import React from "react";
import decode from "jwt-decode";
import Api from "./Api";
export const tokenName = "GBSMORVARID";
export default class AuthService extends React.Component {
  // Initializing important variables
  constructor(props) {
    super(props);
    this.fetch = this.fetch.bind(this); // React binding stuff
    this.login = this.login.bind(this);
    this.loginTwoFa = this.loginTwoFa.bind(this);
    this.getProfile = this.getProfile.bind(this);
    this.getUsername = this.getUsername.bind(this);
    this.isAdmin = this.isAdmin.bind(this);
    this.setToken = this.setToken.bind(this);
    this.loginDynamicCode = this.loginDynamicCode.bind(this);

  }
  async login(user) {
    return new Api()
      .post("/api/auth/customerLogin", {
        body: JSON.stringify(user),
      })
      .then(async (response) => {
        const data = await response.json();
        if (response.status == 200) {
          this.setToken(data.token);
          return { profileInfo: this.getProfile() };
        } else {
          return null;
        }
      })
      .catch((e) => {
        console.log("err", e);
        return null;
      });
  }

  async loginTwoFa(userInfo) {
    return new Api()
      .post("/api/user/loginTwoFa", {
        body: JSON.stringify(userInfo),
      })
      .then((c) => {
        return c.json();
      })
      .then((res) => {
        if (res.token) {
          this.setToken(res.token);
          return { profileInfo: this.getProfile() };
        } else {
          return null;
        }
      })
      .catch((e) => {
        console.log("err", e);
        return null;
      });
  }

  loggedIn() {
    // Checks if there is a saved token and it's still valid
    const token = this.getToken(); // GEtting token from localstorage
    return token && !this.isTokenExpired(token); // handwaiving here
  }
  isAdmin() {
    const token = this.getToken(); // GEtting token from localstorage
    const decoded = decode(token);
    if (decoded.groupID == "1") return true;
    else return false;
  }

  isTokenExpired(token) {
    try {
      const decoded = decode(token);
      if (
        decoded.exp < Date.now() / 1000 &&
        decoded.hasOwnProperty(
          "http://schemas.xmlsoap.org/ws/2005/05/identity/claims/name"
        )
      ) {
        // Checking if token is expired. N
        return true;
      } else return false;
    } catch (err) {
      this.logout();
      return true;
    }
  }
  getUsername(decoded) {
    return decoded[
      "http://schemas.xmlsoap.org/ws/2005/05/identity/claims/name"
    ];
  }
  setToken(idToken) {
    // Saves user token to localStorage
    localStorage.setItem(tokenName, idToken);
  }

  getToken() {
    // Retrieves the user token from localStorage
    return localStorage.getItem(tokenName);
  }

  logout() {
    // Clear user token and profile data from localStorage
    localStorage.removeItem(tokenName);
  }

  getProfile() {
    // Using jwt-decode npm package to decode the token
    if (this.getToken()) {
      const decoded = decode(this.getToken());
      if (decoded.exp > Date.now() / 1000) return decoded;
      else return 0;
    } else return 0;
  }

  fetch(url, options) {
    // performs api calls sending the required authentication headers
    const headers = {
      "Content-Type": "application/json",
    };

    // Setting Authorization header
    // Authorization: Bearer xxxxxxx.xxxxxxxx.xxxxxx
    // if (this.loggedIn()) {
    //   headers["Authorization"] = "Bearer " + this.getToken();
    // }

    return fetch(url, {
      headers,
      ...options,
    })
      .then(this._checkStatus)
      .then((response) => {
        return response.json();
      });
  }

  _checkStatus(response) {
    // raises an error in case response status is not a success
    if (response.status >= 200 && response.status < 300) {
      // Success status lies between 200 to 300
      return response;
    } else {
      var error = new Error(response.statusText);
      error.response = response;
      throw error;
    }
  }


  async loginDynamicCode(user) {
    return new Api()
      .post("/api/website/verifyMobile/dynamicCode", {
        body: JSON.stringify(user),
      })
      .then(async (response) => {
        const data = await response.json();
        if (response.status == 200) {
          this.setToken(data.token);
          return { profileInfo: this.getProfile() };
        } else {
          return null;
        }
      })
      .catch((e) => {
        console.log("err", e);
        return null;
      });
  }
}
