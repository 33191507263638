export const BASENAME = ""; // don't add '/' at end off BASENAME for breadcrumbs
export const BASE_URL = "https://www.restoranmorvarid.com";
export const BASE_TITLE = " | رستوران مروارید";

export const CONFIG = {
  layout: "horizontal", // vertical, horizontal
  subLayout: "", // horizontal-2
  collapseMenu: false, // mini-menu
  layoutType: "menu-light", // menu-dark, menu-light, dark
  headerBackColor: "header-red", // header-blue, header-red, header-purple, header-info, header-green header-dark
  rtlLayout: true,
  navFixedLayout: true,
  headerFixedLayout: true,
  boxLayout: false,
  jwt: {
    secret: "SECRET-KEY",
    timeout: "60 days",
  },
  openLoginForm: false,
  ShopCount: 0,
};
