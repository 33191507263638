import {
  DashboardScreen, TranslationsType, MainMenu, SignScreen, MyWalletScreen,
  General,
  FastConvertScreen,
  WithdrawScreen,
  DepositScreen,
  MoneyDepositScreen,
  HistoryScreens,
  ProfileInfo,
  TradeScreen,
  AboutUs,
  Confirmations,
  NavSearchBox,
  SearchRsultPage,
  Searchpage,
  NavUserLogin,
  Mainslider,
  Guneshcomponent,
  Guneshcard,
  Footer,
  Product,
  Productscontiner,
  NavTop,
  Elementors,
  RecentContent,
  Representationcart,
  UserPanel,
  SignIn,
  NewUser,
  Productdetile,
  Blogitem,
  Contactus,
  Blogdetile,
  OptionItem,
  Productitem,
  Cooperate,
  HomeParanox,
} from "./types";

const dashboardScreen: DashboardScreen = {
  title: "TitleX",
};

const aboutUs: AboutUs = {
  AboutUsTitle: "About us",
  AboutUsTitle2: "why Us?",
  AboutUsTitle3: "Board of Directors",
  BodyText1: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Egestas purus viverra accumsan in nisl nisi. Arcu cursus vitae congue mauris rhoncus aenean vel elit scelerisque. In egestas erat imperdiet sed euismod nisi porta lorem mollis. Morbi tristique senectus et netus. Mattis pellentesque id nibh tortor id aliquet lectus proin. Sapien faucibus et molestie ac feugiat sed lectus vestibulum. Ullamcorper velit sed ullamcorper morbi tincidunt ornare massa eget. Dictum varius duis at consectetur lorem. Nisi vitae suscipit tellus mauris a diam maecenas sed enim. Velit ut tortor pretium viverra suspendisse potenti nullam. Et molestie ac feugiat sed lectus. Non nisi est sit amet facilisis magna. Dignissim diam quis enim lobortis scelerisque fermentum. Odio ut enim blandit volutpat maecenas volutpat. Ornare lectus sit amet est placerat in egestas erat. Nisi vitae suscipit tellus mauris a diam maecenas sed. Placerat duis ultricies lacus sed turpis tincidunt id aliquet. ",
  BodyText2: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Egestas purus viverra accumsan in nisl nisi. Arcu cursus vitae congue mauris rhoncus aenean vel elit scelerisque. In egestas erat imperdiet sed euismod nisi porta lorem mollis. Morbi tristique senectus et netus. Mattis pellentesque id nibh tortor id aliquet lectus proin. Sapien faucibus et molestie ac feugiat sed lectus vestibulum. Ullamcorper velit sed ullamcorper morbi tincidunt ornare massa eget. Dictum varius duis at consectetur lorem. Nisi vitae suscipit tellus mauris a diam maecenas sed enim. Velit ut tortor pretium viverra suspendisse potenti nullam. Et molestie ac feugiat sed lectus. Non nisi est sit amet facilisis magna. Dignissim diam quis enim lobortis scelerisque fermentum. Odio ut enim blandit volutpat maecenas volutpat. Ornare lectus sit amet est placerat in egestas erat. Nisi",
  BodyTextList1: "Originality of the product brand",
  BodyTextList2: "Appropriate price",
  BodyTextList3: "Daily delivery",
  BodyTextList4: "Customer service",
}

const confirmations: Confirmations = {
  ConfirmationsTitle: 'Confirmations and Certifications',
}
const navSearchBox: NavSearchBox = {
  NavSearchBoxTitle: "Search...",
}
const searchRsultPage: SearchRsultPage = {
  ShowDetails: "View product details",
}
const searchPage: Searchpage = {
  SearchPageTitle: "Search result",
  ResultNotFound: 'The search result was not found',
  BackToMainpage: 'Back to main page',
}
const navUserLogin: NavUserLogin = {
  LoginToAccount: 'Login to account',
}



//A:mycomponents------------

const mainSlider: Mainslider = {
  ShowMore: 'Show more',
}

const guneshComponent: Guneshcomponent = {
  ShowMoreProduct: 'show more product',
  BestSellingProducts: 'Best selling products',
}
const guneshCard: Guneshcard = {
  AddToCart: 'Add to cart',
  ProductTitle: 'Product title',
}
const footer: Footer = {
  WeWating: 'We are waiting to meet you:',
  CustomerGuide: 'Customer guide',
  HowToOrder: 'How to order',
  HowToTrackTheOrder: 'How to track the order',
  OrderSubmissionProcess: 'Order submission process',
  followUs: 'Follow us:',
  WhatNeedToNow: 'Everything you need to know about Learn Store:',
  CopyRight: 'All rights reserved.',
  Compony: 'Gunesh Bilgi Sayar Sahand Company.',

}

const product: Product = {
  AllProducts: 'All products',
  Categories: 'Categories',
}

const productsContiner: Productscontiner = {
  LoadMoreProducts: 'Load more products',
  NotMessage: 'There are no products to display.',
  ShowAllProducts: 'Show all products',
  SortByLatest: 'Sort by latest',
  SortByAscPrice: 'Sort by cost: cheap to expensive',
  SortByDesPrice: 'Sort by cost: expensive to cheap',
}

const productDetile: Productdetile = {
  ProductDetails: 'Product details :',
  Attributes: 'Attributes :',
  Viscosity: ':Viscosity :',
  Capacity: 'Capacity :',
  Quality: 'Quality :',
  Model: 'Model :',
  Price: 'Price :',
  Toman: 'Toman',
  AddToCart: 'Add to cart',
  OtherDetails: 'Other details',
  GeneralSpecifications: 'General specifications',

}
const blogItem: Blogitem = {
  ReadMore: 'Read more',
}

const contactUs: Contactus = {
  Contact: 'Contact us',
  Address: 'Address 1',
  Address1: "Address 2",

  Phone: 'Phone',
  WorkingHours: 'Working hours',
  Email: 'Email',
  ComponyOnMap: 'Petronam company address on the map',
  SelectTopic: 'Select a topic',
  Criticism: 'Criticism',
  Complaint: 'Complaint',
  OrderTracking: 'Order tracking',
  Originality: 'Inquiry into the authenticity of the goods',
  Name: 'First name',
  Family: 'Last name',
  Mobile: 'Mobile',
  MessageText: 'Message text',
  RegisterAndSend: 'Register and send',
}

const blogDetile: Blogdetile = {
  UsersOptions: 'Users options',
  SendMessage: 'Please send your options to us',
  ReplayTo: 'Replay to:',
  Name: 'Name',
  Family: 'Family',
  OptionText: 'Option text',
  Send: 'Send',
}
const cooperate: Cooperate = {
  CSendMessage: 'If you want to cooperate, contact us through the form below',
  CName: 'Name',
  CFamily: 'Family',
  COptionText: 'Option text',
  CSend: 'Send',
}

const optionItem: OptionItem = {
  ReplayTO: 'Replay to : ',
  Replay: 'Replay',
}
const productItem: Productitem = {
  Title: 'Product title',
  Toman: 'T',
  AddToCart: 'Add to cart',
}


//--------------------------

const navTop: NavTop = {
  NavTopAddress: 'Iran, Tabriz, University of Tabriz, Center for Growth and Innovation of Technology Units',
  NavTopPhone: '09143106404',
}

const elementors: Elementors = {
  ElementorsTitle1: 'High quality',
  ElementorsTitle2: 'Speed',
  ElementorsTitle3: 'Wide range of services',
  ElementorsTitle4: 'Low price',
  ElementorsBody1: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Egestas purus viverra accumsan in nisl nisi. Arcu cursus vitae congue mauris rhoncus aenean vel elit scelerisque.',
  ElementorsBody2: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Egestas purus viverra accumsan in nisl nisi. Arcu cursus vitae congue mauris rhoncus aenean vel elit scelerisque.',
  ElementorsBody3: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Egestas purus viverra accumsan in nisl nisi. Arcu cursus vitae congue mauris rhoncus aenean vel elit scelerisque.',
  ElementorsBody4: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Egestas purus viverra accumsan in nisl nisi. Arcu cursus vitae congue mauris rhoncus aenean vel elit scelerisque.',
}

const recentContent: RecentContent = {
  RecentContentTitle: 'Recent content',
  RecentContentBtn: 'Show all',
}

const representationCart: Representationcart = {
  RepresentationcartTitle: 'Representation',
  RepresentationcartPhone: 'Phone',
  RepresentationcartAddress: 'Address',
  RepresentationcartInfo: 'Info',
}

const userPanel: UserPanel = {
  userPanelProfile: 'Profile',
  userPanelInbox: 'Message box',
  userPanelExit: 'Logout',
}

const signIn: SignIn = {
  SignInTitle: 'Login to the panel',
  SignInUserName: 'Username or Email',
  SignInPassword: 'Password',
  SignInRememberMe: 'Remember me',
  SignInLogin: 'Login',
  SignInDontHaveAccount: 'Do not have an account?',
  SignInRegister: 'Registeratoin',
  SignInForgotPassword: 'Forgot your password?',
  ErrorMessageUserNameEmpty: 'Username is empty',
  ErrorMessageUserName: 'Username is incorrect',
  ErrorMessagePasswordEmpty: 'Password is empty',
  ErrorMessagePassword: 'Password is incorrect',
}

const newUser: NewUser = {
  NewUserTitle: 'Registeratoin',
  NewUserRegister: 'Registeratoin',
  NewUserBackToLoginIn: 'Back to the login page',
  NewUserUserName: 'User name:',
  NewUserPhone: 'Phone:',
  NewUserEmail: 'Email:',
  NewUserPassword: 'Password:',
  NewUserRePassword: 'Repeat password:',
  ErrorMessageUserNameEmpty: 'Username is empty',
  ErrorMessagePhoneEmpty: 'Phone is empty',
  ErrorMessagePhone: 'Phone is incorrect',
  ErrorMessageEmailEmpty: 'Email is empty',
  ErrorMessageEmail: 'Email is incorrect',
  ErrorMessagePasswordEmpty: 'Password is empty',
  ErrorMessageRePassword: 'Repeat password is incorrect',
  ErrorMessageRePasswordEmpty: 'Repeat password is empty',
}

const mainMenu: MainMenu = {
  // DashboardTitle: 'Dashboard',
  // ActiveOrdersTitle: 'Active Orders',
  // MainMarketTitle: 'Main Markets',
  // MyWalletTitle: 'My Wallets',
  // ProfessionalMarketsTitle: 'Professional Markets',
  // MarketOrderTitle: 'Market Order',
  // fastConvertTitle: 'Fast Convert'
  TitleHome: 'Home',
  TitleProducts: 'Products',
  TitleRepresentation: 'Representation',
  TitleCooperate: 'Invited to cooperate',
  TitleWeblog: 'Weblog',
  TitleAboutUs: 'AboutUs',
  TitleContactUs: 'ContactUs',
  TitleSaveOrder: 'SaveOrder',
  TitleCooperateMain: 'Invited to Petronam',
  TitleParanox: 'Oil Paranox'


}


const signScreen: SignScreen = {
  SignupTitle: "Sign Up",
  Emailaddress: "* Email ddress",
  Password: "* Password",
  RepeatPassword: "* Repeat Password",
  AcceptRules1: "I have read and agree to the ",
  AcceptRules2: "terms and conditions",
  AcceptRules3: "of GBS services.",
  InvitationCode: "Invitation Code",
  InvitationCodeTextInfo: "- Enter if you have received an invitation code from your friends.",
  AlreadyHaveAnAccount: "Already have an account?",
  AlreadyHaveAnAccountSigninLink: "Sign in",
  SapratorSignupAndGoogleAccount: "OR",
  SignWithGoogle: "Sign With Google",
  RememberMe: "Remember me",
  SigninTitle: "Sign in",
  DoNotHaveAccount: "If you don't have account?",
  loginErrorLabel: 'Email or password is wrong !',
  Name: "Name",
  Last: "Last"
}

const myWalletScreen: MyWalletScreen = {
  historyTitle: 'my wallets'
}

const general: General = {
  amount: 'amount',
  deposit: 'deposit',
  options: 'options',
  price: 'price',
  withdraw: 'withdraw',
  currentFee: 'current fee',
  filled: 'filled',
  market: 'market',
  orderPrice: 'order price',
  state: 'state',
  time: 'time',
  totalPrice: 'total price',
  type: 'type',
  direction: 'direction',
  cancel: 'cancel',
  fastConvert: 'fast convert',
  coin: 'coin',
  delete: 'Delete',
  logoutLabel: 'Logout',
  myMessagesLabel: 'My Messages',
  profileLabel: 'Profile',
  settingsLabel: 'Settings',
  noRowsToDisplayLabel: 'There is no any rows to display',
  balance: 'Balance',
  priceUnit: 'T'
}


const fastConvertScreen: FastConvertScreen = {
  pageTitle: 'fast convert',
  modeDropDownData: [
    { value: "few", label: "Convert few to rls" },
    { value: "torls", label: "Convert all to rls" },
    { value: "tousdt", label: "Convert all to usdt" },
  ],
  modeLabel: 'mode',
  convertButtonText: 'Convert Now'
}

const withdrawScreen: WithdrawScreen = {
  amountLabel: 'Withdraw Amount',
  backButton: 'Back',
  createRequestButton: 'Create request',
  feeLabel: 'Withdraw fee',
  pageTitle: 'Withdraw request',
  targetWalletLabel: 'Destination wallet address',
  twoFactorLabel: 'Two Factor Authentication Code',
  viewFeeButton: 'View Fees',
  activeWithdrawAmount: 'Your active balance',
  targetWalletTagLabel: 'Destination wallet address tag',
  amountError: 'Amount is required',
  authenticationCodeError: 'Two factor authentication code is required',
  targetWalletAddressError: 'Target wallet address is required'
}

const depositScreen: DepositScreen = {
  amount: 'Amount',
  createWalletButton: 'Create Wallet Button',
  createWalletDescription: 'To start depositing coins, create a wallet address for yourself by clicking this button',
  currentBalanceTitle: 'Current Balance',
  depositHistoryTitle: 'Deposit History',
  description: 'Description',
  myWalletsButton: 'My Wallets',
  pageTitle: 'Depost',
  timeLabel: 'Time'
}

const moneyDepositScreen: MoneyDepositScreen = {
  addNewCardButton: 'Add New Card',
  amountLabel: 'Amount',
  bodyText: 'Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo consequat. Duis autem vel eum iriure dolor in hendrerit in vulputate velit esse molestie consequat, vel illum dolore eu feugiat nulla facilisis at vero eros et accumsan et iusto odio dignissim qui blandit praesent luptatum zzril delenit augue duis dolore te feugait nulla facilisi.',
  cardSelectLabel: 'Please select your card to deposit',
  goToBankButton: 'Go To Bank',
  pageTitle: 'Fiat',
  amountError: 'Amount is required',
}

const historyScreens: HistoryScreens = {
  amountLabel: 'Amount',
  exchangeLabel: 'Exchange',
  feeLabel: 'Fee',
  filledLabel: 'Filled',
  orderPriceLabel: 'Order Price',
  ordersHistoryTitle: 'Orders History',
  paginationLimitlabel: 'Limit',
  sideLabel: 'Side',
  statusLabel: 'Status',
  timeLabel: 'Time',
  totalLabel: 'Total',
  typeLabel: 'Type',
  searchLabel: 'Search',
  priceLabel: 'Price',
  volumeLabel: 'Volume',
  tradeHistoryTitle: 'Trades History',
  balanceLabel: 'Balance',
  currencyLabel: 'Currency',
  descriptionLabel: 'Description',
  exportReportButton: 'Export Excel',
  transactionHistoryTitle: 'Transcation History',
  transactionIDlabel: 'TrxID',
  actionsLabel: 'Actions',
  depositAmountLabel: 'DepositAmount',
  depositHistoryTitle: 'Deposit History',
  recivedAmountLabel: 'RecivedAmountLabel',
  recivingAddressLabel: 'Reciveing Address',
  withdrawHistoryTitle: 'Withdraw History'
}

const profileInfo: ProfileInfo = {
  personalDetailsTitle: 'Personal Details',
  fullName: 'Full Name',
  fistName: 'Fist Name',
  lastName: 'Last Name',
  nationalCode: 'National code',
  mobile: 'Mobile',
  save: 'Save',
  bankCards: 'Bank Cards',
  cardNumber: 'Card Number',
  accountInfo: 'Account Info',
  otherInfo: 'Other Info',
  myReferals: 'My Referals',
  bankName: 'Bank Name',
  state: 'State',
  action: 'Action',
  IBAN: 'IBAN',
  newCard: 'New Card',
  newIBAN: 'New IBAN',
  address: 'Address',
  accepted: 'Accepted',
  PendingApproval: 'Pending approval',
  currentPassword: 'Current Password',
  newPassword: 'New Password',
  confirmPassword: 'Confirm Password',
  changePasswordTitle: 'Change Password',
  referalTitle: 'Generate referral code',
  generateReferalCode: 'Generate Referal Code',
  referralCode: ' Referral Code',
  yourInvitationLink: 'Your Invitation Link',
  youRecieve: 'You Recieve',
  friendsRecieve: 'Friends Recieve',
  friends: 'Friends',
  totalReferralFees: 'Total Referral Fees',
}

const tradeScreen: TradeScreen = {
  buyButton: "Buy",
  sellButton: "Sell",
  lastPriceLabel: "Last Price Of",
  recivedAmountLabel: "You will receive",
  sellOrdersLabel: "Sell Orders",
  buyOrdersLabel: "Buy Orders",
  activeOrdersLabel: "Your Active Orders",
  marketRecentOrdersLabel: "Market Recent Orders",
  orderTotalPricelabel: 'Total Price'
}


const homeParanox: HomeParanox = {
  title: 'پارانوکس',
}

const translations: TranslationsType = {
  dashboardScreen,
  mainMenu,
  signScreen,
  myWalletScreen,
  general,
  fastConvertScreen,
  withdrawScreen,
  depositScreen,
  moneyDepositScreen,
  historyScreens,
  profileInfo,
  tradeScreen,
  aboutUs,
  confirmations,
  navSearchBox,
  searchRsultPage,
  searchPage,
  navUserLogin,
  mainSlider,
  guneshComponent,
  guneshCard,
  footer,
  product,
  productsContiner,
  navTop,
  elementors,
  recentContent,
  representationCart,
  userPanel,
  signIn,
  newUser,
  productDetile,
  blogItem,
  contactUs,
  blogDetile,
  optionItem,
  productItem,
  cooperate,
  homeParanox
};


export default translations;
