import { useEffect, useState } from "react";
import { TranslationsType } from "./types";
import { createContainer } from "unstated-next";
import en from "./en";
import fa from "./fa";

function LocalizationProvider() {
  const [language, setLanguage] = useState<"en" | "fa">("fa");
  const [values, setValues] = useState<TranslationsType>(en);

  useEffect(() => {
    switch (language) {
      case "en":
        setValues(en);
        break;
      case "fa":
        setValues(fa);
    }
  }, [language]);

  return {
    language,
    setLanguage,
    ...values,
  };
}

export const TranslationContainer = createContainer(LocalizationProvider);
