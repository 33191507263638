import React, { useEffect, useContext, useState } from "react";
import { Link } from "react-router-dom";
import { ConfigContext } from "../../../contexts/ConfigContext";
import useWindowSize from "../../../hooks/useWindowSize";
import logo from "../../../assets/images/morvarid.png";

import NavContent from "./NavContent";
import navigation from "../../../menu-items";
import { useTranslation } from "@hooks/useTranslation";
import AuthService from "@model/AuthService";
import SignIn from "./SignIn";
import NewUser from "./NewUser/NewUser";
import DynamicLogin from "./DynamicLogin/DynamicLogin";

import {

  Modal,

} from "react-bootstrap";
import "./ExitUser.scss";
import JWTContext from "../../../contexts/JWTContext";
import { ACCOUNT_INITIALISE } from "../../../store/actions";

const Navigation = () => {
  const { mainMenu } = useTranslation();
  const [showModal, setShowModal] = useState(false);
  const [showModalRegister, setShowModalRegister] = useState(false);
  const [showModalِDynamic, setShowModalDynamic] = useState(false);
  // const JWTContext = useContext(JWTContext);

  const menuItems = navigation(mainMenu);
  const configContext = useContext(ConfigContext);
  const { user, isLoggedIn } = useContext(JWTContext);
  const {
    layout,
    layoutType,
    navFixedLayout,
    collapseMenu,
    rtlLayout,
    boxLayout,
    subLayout,
    headerFixedLayout,
    openLoginForm,

  } = configContext.state;


  const windowSize = useWindowSize();
  const [userLogin, setUserLogin] = useState();
  //@ts-ignore
  const { dispatch } = configContext;
  const { dispatchJWT } = JWTContext;

  useEffect(() => {
    const profile = new AuthService().getProfile();
    if (profile)
      setUserLogin(profile);
    else {
      setUserLogin(null);
      localStorage.clear();
    }
  }, [configContext]);

  const scroll = () => {
    if (navFixedLayout && headerFixedLayout === false) {
      const main = document.querySelector(".pcoded-navbar");
      const el = document.querySelector(".pcoded-navbar.menupos-fixed");
      const scrollPosition = window.pageYOffset;
      if (scrollPosition > 60) {
        el.style.position = "fixed";
        el.style.transition = "none";
        el.style.marginTop = "0";
      } else {
        main.style.position = "absolute";
        main.style.marginTop = "56px";
      }
    } else {
      document.querySelector(".pcoded-navbar").removeAttribute("style");
    }
  };

  let navClass = ["pcoded-navbar", layoutType];

  if (layout === "horizontal" || windowSize.width > 992) {
    navClass = [...navClass, "theme-horizontal"];
  } else {
    if (navFixedLayout) {
      navClass = [...navClass, "menupos-fixed"];
    }

    if (navFixedLayout && !headerFixedLayout) {
      window.addEventListener("scroll", scroll, true);
      window.scrollTo(0, 0);
    } else {
      window.removeEventListener("scroll", scroll, false);
    }
  }

  if (windowSize.width < 992 && collapseMenu) {
    navClass = [...navClass, "mob-open"];
  } else if (collapseMenu) {
    navClass = [...navClass, "navbar-collapsed"];
  }

  if (layoutType === "dark") {
    document.body.classList.add("gradient-able-dark");
  } else {
    document.body.classList.remove("gradient-able-dark");
  }

  if (rtlLayout) {
    document.body.classList.add("gradient-able-rtl");
  } else {
    document.body.classList.remove("gradient-able-rtl");
  }

  if (boxLayout) {
    document.body.classList.add("container");
    document.body.classList.add("box-layout");
  } else {
    document.body.classList.remove("container");
    document.body.classList.remove("box-layout");
  }

  let navBarClass = ["navbar-wrapper"];
  if (layout === "horizontal" && subLayout === "horizontal-2") {
    navBarClass = [...navBarClass, "container"];
  }
  let navContent = (
    <div className={navBarClass.join(" ")}>
      <Link to="/" className=" b-brand-gbs">
        <img id="main-logo" src={logo} alt="لوگو رستوران مروارید" className="logo" />
      </Link>
      <NavContent navigation={menuItems} />

      <div className="icon-toolbar-box mt-1" >

        <a href="https://instagram.com/restaurant.morvarid" target="_blank">
          <span className="icon-holder icon1">
            <i className="fab fa-instagram"></i>
          </span>
        </a>
        <a href="https://web.whatsapp.com/send?phone=+989141181424&text=سلام وقت بخیر" target="_blank" title="09141181424">
          <span className="icon-holder icon2">
            <i className="fab fa-whatsapp"></i>
          </span>
        </a>

        <a href="tel:+989141181424" title="09141181424">
          <span className="icon-holder icon4">
            <i className="feather icon-phone-call"></i>
          </span>
        </a>
      </div>
      <div className="user-login-gbs">
        {
          (userLogin && userLogin.userId) ?
            <div className="user-exit-edit">
              <span className="user-name-field">
                <span className="hid-in-mobile">نام کاربری:{" "} </span>
                <span className="user-name">{userLogin.fullName} </span>
              </span>
              <div className="exit-edit">
                <Link to="#" onClick={() => { localStorage.clear(); window.location.href = "/"; }} className="exit">
                  {" "}خروج
                </Link>
                <span className="space"> | </span>
                <Link to="#" className="edit" onClick={() => {
                  setShowModalRegister(true)
                }}>
                  {" "}ویرایش
                </Link>
              </div>
            </div>
            :
            <div className="user-login">
              <button
                className="btnG"
                onClick={() => {
                  dispatch({
                    type: "LOGIN",
                    openLoginForm: true,
                  })
                }
                }
              >
                <i className="fa fa-lg fa-user icon-cartG" aria-hidden="true"></i>
                <span className="btn-textG">ورود</span>
              </button>
            </div>

        }
      </div>

      <Modal
        // @ts-ignore
        size="md"
        show={openLoginForm}
        onHide={() => {
          dispatch({
            type: "LOGIN",
            openLoginForm: false,
          });
          dispatchJWT({
            type: ACCOUNT_INITIALISE,
            payload: {
              isLoggedIn: true,
              user: new AuthService().getProfile(),
            },
          });
          setShowModal(false);
        }}

      >
        <SignIn setShowModal={setShowModal} setShowModalRegister={setShowModalRegister} setShowModalDynamic={setShowModalDynamic} />
      </Modal>

      <Modal
        // @ts-ignore 
        size="md"
        show={showModalRegister}
        onHide={() => {
          setShowModalRegister(false);
        }}

      >
        <NewUser setShowModal={setShowModal} setShowModalRegister={setShowModalRegister} />
      </Modal>


      <Modal
        // @ts-ignore
        size="md"
        show={showModalِDynamic}
        onHide={() => {
          dispatch({
            type: "LOGIN",
            openLoginForm: false,
          });
          setShowModalDynamic(false);
        }}

      >
        <DynamicLogin setShowModal={setShowModal} setShowModalDynamic={setShowModalDynamic} setShowModalRegister={setShowModalRegister} />
      </Modal>
      {/* <div className='serachbox-gbs'>
        <SearchBoxIcon >
          <div className='serachbox-navsearchbox'>
            <NavSearchBox />
          </div>
        </SearchBoxIcon>
      </div> */}

    </div >
  );
  if (windowSize.width < 992) {
    navContent = (
      <div className="navbar-wrapper">

        <NavContent navigation={menuItems} />
      </div>
    );
  }
  return (
    <React.Fragment>
      <nav className={navClass.join(" ")}>{navContent}</nav>
    </React.Fragment>
  );
};

export default Navigation;
