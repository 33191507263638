import decode from "jwt-decode";
import axios from "axios";
import { tokenName } from "./AuthService";
import { ApiRoute } from "./ApiRoute";

export default class Api {
  constructor() {
    this.get.bind(this);
    this.post.bind(this);
    this.put.bind(this);
    this.delete.bind(this);
    this.getUserId.bind(this);
    this.getFile.bind(this);
    this.postWithFile.bind(this);
    this.putWithFile.bind(this);
    this.postAxios.bind(this);
    // this.getChatHub.bind(this);
  }
  _getToken = () => {
    // Retrieves the user token from localStorage
    return localStorage.getItem(tokenName);
  };
  // getChatHub() {
  //   return new signalR.HubConnectionBuilder()
  //     .withUrl(this.chatHub)
  //     .build();
  // }
  getUserId() {
    const decoded = decode(this._getToken());
    return { decoded };
  }

  postAxios(url, data, config) {
    axios.post(`${ApiRoute}${url}`, data, config);
  }
  get(url, options) {
    // alert(JSON.stringify(options));

    options = {
      ...options,
      method: "get",
      headers: {
        "Content-Type": "application/json",
        "x-auth-token": this._getToken(),
        // permissionId: options.permissionId,
      },
    };

    return fetch(`${ApiRoute}${url}`, options);
  }

  getFile(url, options) {
    options = {
      ...options,
      headers: {
        "Content-Type": "multipart/form-data",
        responseType: "arraybuffer",
        "x-auth-token": this._getToken(),
        // permissionId: options.permissionId,
      },
    };
    return fetch(`${ApiRoute}${url}`, options);
  }

  post(url, options) {
    options = {
      ...options,
      method: "post",
      headers: {
        "Content-Type": "application/json",
        "x-auth-token": this._getToken(),
        // permissionId: options.permissionId,
      },
    };
    return fetch(`${ApiRoute}${url}`, options);
  }
  postWithFile(url, body, permissionId) {
    let options = {
      body,
      method: "post",
      headers: {
        "x-auth-token": this._getToken(),
        // permissionId: permissionId,
      },
    };
    if (!(body instanceof FormData)) {
      console.log("im if the if");
      var f = new FormData();
      for (const key in body) {
        if (body.hasOwnProperty(key)) {
          f.append(key, body[key]);
        }
      }
      options.body = f;
    }

    return fetch(`${ApiRoute}${url}`, options);
  }
  putWithFile(url, body) {
    var f = new FormData();
    for (const key in body) {
      if (body.hasOwnProperty(key)) {
        f.append(key, body[key]);
      }
    }
    let options = {
      body: f,
      method: "put",
      headers: {
        "x-auth-token": this._getToken(),
      },
    };
    return fetch(url, options);
  }
  put(url, options) {
    options = {
      ...options,
      method: "put",
      headers: {
        "Content-Type": "application/json",
        "x-auth-token": this._getToken(),
        // permissionId: options.permissionId,
      },
    };

    return fetch(`${ApiRoute}${url}`, options);
  }
  delete(url, options) {
    options = {
      ...options,
      method: "delete",
      headers: {
        "Content-Type": "application/json",
        "x-auth-token": this._getToken(),
        // permissionId: options.permissionId,
      },
    };
    return fetch(`${ApiRoute}${url}`, options);
  }
}
