// import { useTranslation } from "./hooks/useTranslation";
import { MainMenu } from "./localization/types";

interface IMenuType {
  id: string;
  title: string;
  type: string;
  children: ISubMenu[],
  icon: string;
  url: string;
  classes: string;

}
interface ISubMenu {
  id: string;
  title: string;
  type: string;
  url: string;
  classes: string;
  icon: string;
  breadcrumbs: boolean;
}

const menuItems = (translate: MainMenu) => {


  return [
    {
      id: "1",
      title: "",
      type: "group",
      icon: "icon-navigation",
      children: [
        {
          id: "2",
          title: translate.TitleHome,
          type: "item",
          url: "/",
          classes: "nav-item",
          icon: "feather icon-home",
        },
        {
          id: "9",
          title: translate.TitleSaveOrder,
          type: "item",
          url: "/foodorder",
          classes: "nav-item",
          icon: "fa fa-check",
          href: "#saveOrder"
        },
        {
          id: "6",
          title: translate.TitleAboutUs,
          type: "item",
          url: "/aboutus",
          classes: "nav-item",
          icon: "fas fa-info",
          // icon: "fas fa-users",
        },

        {
          id: "7",
          title: translate.TitleContactUs,
          type: "item",
          url: "/contactus",
          classes: "nav-item",
          icon: "fas fa-phone",
        },
        {
          id: "8",
          title: "بلاگ",
          type: "item",
          url: "/blog",
          classes: "nav-item",
          icon: "fas fa-blog",
        },
        {
          id: "9",
          title: "لیست غذاها",
          type: "item",
          url: "/food",
          classes: "nav-item",
          icon: "fas fa-utensils",
        },
      ],
    }];

};

export type { IMenuType };
export type { ISubMenu };
export default menuItems;