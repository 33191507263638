import React, { useEffect, useContext, useState } from "react";
import { Link } from "react-router-dom";
import { ConfigContext } from "@contexts/ConfigContext";
// import logo from "../../../assets/images/main-logo.png";
import logo from "../../../assets/images/morvarid.png";
import * as actionType from "../../../store/actions";
import NavTop from "./NavTop/NavTop";
import NavRight from "./NavRight";
import NavLeft from "./NavLeft";
import NavShopCart from "./NavShopCart/NavShopCart";

import SignIn from "../Navigation/SignIn";
import NewUser from "../Navigation/NewUser/NewUser";
import DynamicLogin from "../Navigation/DynamicLogin/DynamicLogin";

import AuthService from "@model/AuthService";
import { Modal } from "react-bootstrap";
const NavBar = () => {
  const [moreToggle, setMoreToggle] = useState(false);
  const configContext = useContext(ConfigContext);
  const {
    collapseMenu,
    headerBackColor,
    headerFixedLayout,
    layout,
    subLayout,
    ShopCount,
    layoutType,
    navFixedLayout,
    rtlLayout,
    boxLayout,
    openLoginForm,
  } = configContext.state;
  // @ts-ignore
  const { dispatch } = configContext;


  const [showModal, setShowModal] = useState(false);
  const [showModalRegister, setShowModalRegister] = useState(false);
  const [showModalِDynamic, setShowModalDynamic] = useState(false);

  let headerClass = [
    "navbar",
    "pcoded-header",
    "navbar-expand-lg",
    headerBackColor,
  ];
  if (headerFixedLayout && layout === "vertical") {
    headerClass = [...headerClass, "headerpos-fixed"];
  }

  let toggleClass = ["mobile-menu"];
  if (collapseMenu) {
    toggleClass = [...toggleClass, "on"];
  }

  const navToggleHandler = () => {
    dispatch({ type: actionType.COLLAPSE_MENU });
  };

  let moreClass = ["mob-toggler"];
  let collapseClass = ["collapse navbar-collapse"];
  if (moreToggle) {
    moreClass = [...moreClass, "on"];
    collapseClass = [...collapseClass, "d-block"];
  }

  const [userLogin, setUserLogin] = useState();
  useEffect(() => {
    setUserLogin(new AuthService().getProfile());
  }, [configContext]);

  let navBar = (
    <React.Fragment>
      <div className="m-header">
        <Link
          to="#"
          className={toggleClass.join(" ")}
          id="mobile-collapse"
          onClick={navToggleHandler}
        >
          <span />
        </Link>

        <Link to="/" className="b-brand">
          <img
            id="main-logo"
            src={logo}
            alt=""
            style={{ height: "3rem", width: "5.1rem" }}
            className="لوگو رستوران مروارید"
          />
        </Link>

        {(userLogin && userLogin.userId) ? (
          <div className="user-exit-edit">
            <span className="user-name-field">
              <span className="hid-in-mobile">نام کاربری:{" "} </span>
              <span className="user-name">{userLogin.fullName} </span>
            </span>
            <div className="exit-edit">
              <Link to="#" onClick={() => { localStorage.clear(); window.location.href = "/"; }} className="exit">
                {" "}خروج
              </Link>
              <span className="space"> | </span>
              <Link to="#" className="edit" onClick={() => {
                setShowModalRegister(true)
              }}>
                {" "}ویرایش
              </Link>
            </div>
          </div>
        ) : (
          <div className="user-login">
            <button
              className="btnG"
              onClick={() => {
                dispatch({
                  type: "LOGIN",
                  openLoginForm: true,
                })
              }
              }
            >
              <i className="fa fa-lg fa-user icon-cartG" aria-hidden="true"></i>
              <span className="btn-textG">ورود</span>
            </button>
          </div>

        )}
        <Link
          to={`/${window.location.href.split("/")[3]}#gotocard`}
          className=""
        >
          <span className="hooya-cart-icon">
            <i className="fa fa-shopping-cart ml-1 f-12 text-surati fa-flip-horizontal" />
            <span className="count rounded-circle d-flex align-items-center justify-content-center">
              {ShopCount}
            </span>
          </span>
        </Link>

        {/* <Link
          to="#"
          className="userlogin-shopcart-mobile"
          style={{ marginRight: "28px", display: "flex" }}
        > */}
        {/* <NavUserLogin>
            <UserPanel />
          </NavUserLogin> */}
        {/* <NavShopCart/> */}
        {/* </Link> */}

        {/* <Link
          to="#"
          className={moreClass.join(" ")}
          onClick={() => setMoreToggle(!moreToggle)}
        >
          <i className="feather icon-more-vertical" />
        </Link> */}
      </div>
      <div className={collapseClass.join(" ")} style={{ Direction: "rtl" }}>
        {/* <NavRight />
        <NavLeft /> */}
        <NavTop />
      </div>

      <Modal
        // @ts-ignore
        size="md"
        show={openLoginForm}
        onHide={() => {
          dispatch({
            type: "LOGIN",
            openLoginForm: false,
          });
          setShowModal(false);
        }}

      >
        <SignIn setShowModal={setShowModal} setShowModalRegister={setShowModalRegister} setShowModalDynamic={setShowModalDynamic} />
      </Modal>

      <Modal
        // @ts-ignore 
        size="md"
        show={showModalRegister}
        onHide={() => {
          setShowModalRegister(false);
        }}

      >
        <NewUser setShowModal={setShowModal} setShowModalRegister={setShowModalRegister} userId={userLogin && userLogin.userId} />
      </Modal>


      <Modal
        // @ts-ignore
        size="md"
        show={showModalِDynamic}
        onHide={() => {
          dispatch({
            type: "LOGIN",
            openLoginForm: false,
          });
          setShowModalDynamic(false);
        }}

      >
        <DynamicLogin setShowModal={setShowModal} setShowModalDynamic={setShowModalDynamic} setShowModalRegister={setShowModalRegister} />
      </Modal>
    </React.Fragment>
  );

  if (layout === "horizontal" && subLayout === "horizontal-2") {
    navBar = <div className="container">{navBar}</div>;
    // <NavTop/>
  }

  return (
    <React.Fragment>
      <header
        style={{
          marginRight: 0,
          height: 0,
          position: "fixed",
          top: 0,
        }}
        className={headerClass.join(" ")}
      >
        {navBar}
      </header>
      {/* <NavTop/> */}
    </React.Fragment>
  );
};

export default NavBar;
