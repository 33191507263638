import React, { useState, useContext, useEffect } from "react";
import "./NewUser.css";
import { Link, useHistory } from "react-router-dom";
import logo from "../../../../assets/images/morvarid.png";
import { useTranslation } from "@hooks/useTranslation";
import { Card, Row, Col, Button, Form as formstyle } from "react-bootstrap";
//@ts-ignore
import PNotify from "pnotify/dist/es/PNotify";
import Api from "@model/Api";
import { ICustomer } from "@model/model";
import * as Yup from "yup";
import { Formik, Field, Form, ErrorMessage } from "formik";
import { ConfigContext } from "@contexts/ConfigContext";
import InputMask from "react-input-mask";
import { useTimer } from "react-timer-hook";
import AuthService from "@model/AuthService";

const NewUser = (p: any) => {
  const { setUserLogin, setShowModal, setShowModalRegister, userId } = p;
  const configContext = useContext(ConfigContext);
  //@ts-ignore
  const { dispatch } = configContext;
  const history = useHistory();

  const { newUser } = useTranslation();
  const [customerInfo, setCustomerInfo] = useState<ICustomer>();

  const [mobileCode, setMobileCode] = useState<string>();
  const [showMobileCode, setShowMobileCode] = useState(false);
  const [mobile, setMobile] = useState<string>();
  const [loginError, setLoginError] = useState("");
  const [showRegisterInfo, setShowRegisterInfo] = useState(false);

  const time = new Date();
  time.setSeconds(time.getSeconds() + 120);
  const { seconds, minutes, isRunning, restart } = useTimer({
    expiryTimestamp: time,
  });

  useEffect(() => {
    const profile = new AuthService().getProfile();
    profile && setUserLogin(profile);
  }, [configContext]);

  useEffect(() => {
    if (userId) {
      setShowRegisterInfo(true);
      new Api()
        .get(`/api/website/customerInfo`)
        .then(async (response) => {
          const { customer } = await response.json();
          if (response.status === 200) {
            setCustomerInfo(customer);
          } else {
            PNotify.error({
              title: "عمیلیات ناموفق",
              // text: data.errors[0].msg,
            });
          }
        })
        .catch((e) => {
          console.log("err", e);
        });
    } else {
      setCustomerInfo({} as ICustomer);
    }
  }, []);

  async function onMobileFormSubmit(e: any) {
    e.preventDefault();
    new Api()
      .get(`/api/website/sendsmstoVerify/${mobile}`)
      .then(async (response) => {
        const data = await response.json();
        if (response.status === 200) {
          setShowMobileCode(true);
          const time = new Date();
          time.setSeconds(time.getSeconds() + 120);
          restart(time);
        } else {
          PNotify.error({
            title: "عملیات ناموفق",
            text: data.errors[0].msg,
          });
        }
      });
    setLoginError("");
  }

  async function onMobileCodeFormSubmit(e: any) {
    e.preventDefault();
    new Api()
      .get(`/api/website/verifyMobile/${mobileCode}/${mobile}`)
      .then(async (response) => {
        const data = await response.json();
        if (response.status === 200) {
          PNotify.success({
            title: "عملیات موفق",
            text: "شماره موبایل تایید شد.",
          });
          setShowRegisterInfo(true);
          setCustomerInfo(data.customer);
        } else {
          PNotify.error({
            title: "عملیات ناموفق",
            text: data.errors[0].msg,
          });
        }
      });
    setLoginError("");
  }

  const SchemaCustomerInfo = Yup.object().shape({
    fullName: Yup.string().required("نام و نام خانوادگی را وارد کنید"),
    // address[]: Yup.string().required("آدرس را وارد کنید"),
    password: Yup.string()
      .required("رمز عبور  را وارد کنید")
      .min(6, "رمز عبور جدید حداقل باید 6 کاراکتر باشد"),

    repassword: Yup.string()
      .required("تکرار رمز عبور را وارد کنید")
      .oneOf([Yup.ref("password"), null], "تکرار رمز عبور صحیح نیست"),

  });

  return (
    <section className="auth-wrapper">
      <Row className="align-items-center text-center">
        <Col md={12} className="p-5">
          <Row className="align-items-center text-center">
            <Col md={12}>
              <img
                id="main-logo"
                src={logo}
                style={{ height: "100px" }}
                alt="لوگو رستوران مروارید"
                className="logo"
              />
            </Col>
          </Row>
          <Row className="align-items-center text-center">
            <Col md={12} className="p-3">
              <p className="reg-title">
                {userId && customerInfo
                  ? `ویرایش اطلاعات ${customerInfo.fullName}`
                  : "ثبت نام و تکمیل اطلاعات کاربری"}
              </p>
            </Col>
          </Row>

          {!showRegisterInfo ? (
            <Row>
              {!showMobileCode && (
                <Card.Body>
                  {" "}
                  <h4 className="mb-3 f-w-200">شماره موبایل را وارد کنید</h4>
                  <form onSubmit={onMobileFormSubmit}>
                    <InputMask
                      style={{ direction: "ltr" }}
                      autoFocus
                      className="form-control text-center mb-2"
                      mask=" 0\999  999  99  99"
                      name="mobile"
                      alwaysShowMask
                      value={mobile}
                      onChange={(e: any) => {
                        const mo = (e.target.value as string).replace(
                          /\D/g,
                          ""
                        );
                        setMobile(mo);
                      }}
                    />
                    {loginError && (
                      <div
                        style={{
                          backgroundColor: "#ff6161",
                          padding: 5,
                          borderRadius: 7,
                          margin: 7,
                          color: "#fff",
                          boxShadow: "1px 3px 10px -1px #888",
                        }}
                      >
                        {loginError}
                      </div>
                    )}
                    <button
                      disabled={!(mobile && mobile.length == 11)}
                      type="submit"
                      className={
                        "btn btn-success btn-block mb-3 " +
                        (!(mobile && mobile.length == 11) &&
                          "btn-outline-success")
                      }
                    >
                      دریافت کد
                    </button>
                  </form>
                </Card.Body>
              )}
              {showMobileCode && (
                <Card.Body>
                  {" "}
                  <h4 className="mb-3 f-w-200">
                    کد ارسال شده به موبایل خود را وارد کنید
                  </h4>
                  <form onSubmit={onMobileCodeFormSubmit}>
                    <InputMask
                      style={{ direction: "ltr" }}
                      autoFocus
                      className="form-control text-center mb-2"
                      mask="9999"
                      name="code"
                      alwaysShowMask
                      value={mobileCode}
                      onChange={(e: any) => {
                        const code = (e.target.value as string).replace(
                          /\D/g,
                          ""
                        );
                        setMobileCode(code);
                      }}
                    />
                    {loginError && (
                      <div
                        style={{
                          backgroundColor: "#ff6161",
                          padding: 5,
                          borderRadius: 7,
                          margin: 7,
                          color: "#fff",
                          boxShadow: "1px 3px 10px -1px #888",
                        }}
                      >
                        {loginError}
                      </div>
                    )}

                    <div style={{ display: "flex", justifyContent: "center" }}>
                      <p>
                        {" "}
                        <span className="f-14"> زمان باقیمانده: </span>
                        <span className="f-14 text-info">
                          {minutes}:{seconds}
                        </span>
                        {!isRunning && (
                          <Button
                            variant="link"
                            className="mr-2 p-0 f-12"
                            style={{ textDecoration: "none" }}
                            onClick={onMobileFormSubmit}
                          >
                            <span className="text-info">ارسال دوباره</span>
                            <i className="fas fa-undo text-info mr-1" />
                          </Button>
                        )}
                      </p>
                    </div>

                    <button
                      disabled={!(mobileCode && mobileCode.length == 4)}
                      type="submit"
                      className={
                        "btn btn-success btn-block mb-3 " +
                        (!(mobileCode && mobileCode.length == 4) &&
                          "btn-outline-success")
                      }
                    >
                      تایید کد
                    </button>
                  </form>
                </Card.Body>
              )}
            </Row>
          ) : (
            <Row className="p-0">
              <Col className="p-0">
                {customerInfo && (
                  <Formik
                    validationSchema={SchemaCustomerInfo}
                    initialValues={{
                      fullName: customerInfo.fullName,
                      address: customerInfo.address,
                      username: customerInfo.username,
                    }}
                    onSubmit={(values, actions) => {
                      values.address = customerInfo.address;
                      values.username = customerInfo?.call && customerInfo.call[0]?.name;

                      if (userId) {
                        new Api()
                          .put(`/api/website/editCustomer`, {
                            body: JSON.stringify({
                              ...values,
                            }),
                          })
                          .then(async (response) => {
                            const data = await response.json();
                            if (response.status === 200) {
                              setCustomerInfo(data.updatedCustomer);
                              PNotify.success({
                                title: "عملیات موفق",
                                text: "اطلاعات با موفقیت ویرایش گردید",
                              });
                              history.replace("/");
                              setShowModal(false);
                              dispatch({
                                type: "LOGIN",
                                openLoginForm: false,
                              });
                            } else {
                              PNotify.error({
                                title: "عملیات ناموفق",
                                text: data.errors[0].msg,
                              });
                            }
                          })
                          .catch((e) => {
                            console.log("err", e);
                          });
                      } else {
                        new Api()
                          .put(
                            `/api/website/addCustomer/${customerInfo?._id}`,
                            {
                              body: JSON.stringify({ ...values }),
                            }
                          )
                          .then(async (response) => {
                            const data = await response.json();
                            if (response.status === 200) {
                              setCustomerInfo(data.newCustomer);
                              PNotify.success({
                                title: "عملیات موفق",
                                text: "ثبت نام با موفقیت انجام شد.",
                              });
                              const profileInfo: any =
                                await new AuthService().login({
                                  username: data.newCustomer.username,
                                  // @ts-ignore
                                  password: values.password,
                                  rememberMe: true,
                                });
                              if (profileInfo) {
                                history.replace("/");
                                setShowModal(false);
                                dispatch({
                                  type: "LOGIN",
                                  openLoginForm: false,
                                });

                                PNotify.success({
                                  title: "عملیات موفق",
                                  text: "به سیستم وارد شدید.",
                                });
                              }
                            } else {
                              PNotify.error({
                                title: "عملیات ناموفق",
                                text: data.errors[0].msg,
                              });
                            }
                          })
                          .catch((e) => {
                            console.log("err", e);
                          });
                      }
                      setTimeout(() => {
                        actions.setSubmitting(false);
                      }, 3000);
                      setShowModalRegister(false);
                      dispatch({
                        type: "LOGIN",
                        openLoginForm: false,
                      });
                    }}
                    render={(props) => (
                      <Form>
                        <Row className="mb-3">
                          <Field
                            autocomplete="off"
                            type="text"
                            name="fullName"
                            className="form-control"
                            placeholder="نام و نام خانوادگی"
                          />
                          <ErrorMessage name="fullName">
                            {(msg) => (
                              <div className="text-c-red f-12">{msg}</div>
                            )}
                          </ErrorMessage>
                        </Row>
                        <Row className="mb-3 pr-0">
                          <textarea
                            name="address"
                            className="form-control"
                            placeholder="آدرس خود را برای تحویل غذا وارد کنید"
                            value={
                              customerInfo.address &&
                              customerInfo.address[0]?.name
                            }
                            onChange={(e: any) => {
                              setCustomerInfo({
                                ...customerInfo,
                                address: [{ name: e.target.value }],
                              });
                            }}
                          />
                          <ErrorMessage name="address">
                            {(msg) => (
                              <div className="text-c-red f-12">{msg}</div>
                            )}
                          </ErrorMessage>
                        </Row>
                        <Row className="mb-3">
                          <Field
                            type="text"
                            disabled
                            name="username"
                            className="form-control"
                            value={`نام کاربری: ${customerInfo.call && customerInfo.call[0]?.name
                              }`}
                          />
                        </Row>
                        <Row className="mb-3">
                          <Field
                            type="password"
                            name="password"
                            className="form-control"
                            placeholder="رمز عبور"
                          />
                          <ErrorMessage name="password">
                            {(msg) => (
                              <div className="text-c-red f-12">{msg}</div>
                            )}
                          </ErrorMessage>
                        </Row>

                        <Row className="mb-3">
                          <Field
                            type="password"
                            name="repassword"
                            className="form-control"
                            placeholder="تکرار رمز عبور"
                          />
                          <ErrorMessage name="repassword">
                            {(msg) => (
                              <div className="text-c-red f-12">{msg}</div>
                            )}
                          </ErrorMessage>
                        </Row>

                        <Row className="p-0 m-0 mb-2 ">
                          <Col className="p-0 m-0">
                            <Button
                              disabled={
                                !(
                                  (props.isValid && props.dirty &&
                                    customerInfo?.address && customerInfo.address[0]?.name
                                  )
                                ) || props.isSubmitting
                              }
                              className={`sm-col-2 mt-4 btn-signIn mb-3 `}
                              type="submit"
                            >
                              {!props.isSubmitting ? (
                                " تکمیل ثبت نام"
                              ) : (
                                <div
                                  className="spinner-grow spinner-grow-sm"
                                  style={{ marginBottom: "3px" }}
                                  role="status"
                                ></div>
                              )}
                            </Button>
                          </Col>
                        </Row>
                      </Form>
                    )}
                  />
                )}
              </Col>
            </Row>
          )}

          {/* <div className="register-signin-reg">
            <p>
              <Link to="/" onClick={() => {
                dispatch({
                  type: "LOGIN",
                  openLoginForm: false,
                });
                setShowModal(true);
                setShowModalRegister(false);
              }} className="login-p">
                {newUser.NewUserBackToLoginIn}
              </Link>
            </p>
          </div> */}
        </Col>
      </Row>
    </section>
  );
};
export default React.memo(NewUser);
