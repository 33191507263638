import React, { useEffect, useContext, useState } from "react";
import { Link, useHistory } from "react-router-dom";

import "./NavTop.css";
import { ListGroup, Dropdown, Media } from "react-bootstrap";

import { ConfigContext } from "../../../../contexts/ConfigContext";
import * as actionType from "../../../../store/actions";
import fa from '../../../../assets/images/langs/fa.svg';
import en from '../../../../assets/images/langs/en.svg';
import NavToggleButton from "../NavToggleButton/NavToggleButton.jsx";
import NavShopCart from "../NavShopCart/NavShopCart";
import { settings } from "@contexts/settings";
import IR from "../FlagImage/IR2.png";
import EN from "../FlagImage/EN.png";
import { useTranslation } from "@hooks/useTranslation";
import AuthService from "@model/AuthService";

const Checked = () => (
  <>{<img src={EN} className="flag" alt="EN Flag"></img>}</>
);
const UnChecked = () => (
  <>{<img className="flag flag-en" src={IR} alt="IR Flag"></img>}</>
);

const NavTop = (props) => {
  const configContext = useContext(ConfigContext);
  const { dispatch } = configContext;
  const { rtlLayout } = configContext.state;

  const { setLanguage, language } = useTranslation();

  //  const { lang, setLang } = useState(settings);
  const { navTop } = useTranslation();
  const [userLogin, setUserLogin] = useState();
  useEffect(() => {
    setUserLogin(new AuthService().getProfile());
    // userLogin && userLogin.userId && alert(userLogin.userId);

  }, []);
  return (
    <header className="toggle-toolbar">
      <React.Fragment>
        <header className="nav-top">

          {/* <ListGroup.Item as="li" bsPrefix=" ">
            <Dropdown alignRight={!rtlLayout} className="drp-user" onSelect={(selectedLang) => {
              if (language != selectedLang) {
                dispatch({ type: actionType.RTL_LAYOUT })
                setLanguage(selectedLang);
              }
            }} >
              <Dropdown.Toggle
                as={Link}
                variant="link"
                to="#"
                id="dropdown-basic"
              >
                <img
                  src={language == 'fa' ? fa : en}
                  className="lang-img"
                  alt="language"
                />
              </Dropdown.Toggle>

              <Dropdown.Menu alignRight className="profile-notification g-language-box">

                <Dropdown.Item eventKey="fa">
                  <Media>

                    <img
                      src={fa}
                      className="lang-img"
                      alt="language"
                    /> <span>فارسی</span>

                  </Media>
                </Dropdown.Item>

                <Dropdown.Item eventKey="en">
                  <Media>
                    <img
                      src={en}
                      className="lang-img"
                      alt="language"
                    /> <span>English</span>
                  </Media>
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </ListGroup.Item> */}


          {/* <span className="toggle-icon">
            <NavToggleButton
              onChange={() => {
                if (language === "fa") {
                  dispatch({ type: actionType.RTL_LAYOUT })
                  setLanguage("fa");
                } else {
                  setLanguage("en");
                }
              }}
              icons={{ checked: <Checked />, unchecked: <UnChecked /> }}
            />
          </span> */}

          {/* <div className="phone-adress">
            <span className="adressG">
              <i className="fa fa-lg fa-map-marker icon-p-a"></i>
              {navTop.NavTopAddress}
            </span>
            <span className="phoneG">
              {navTop.NavTopPhone}
              <i className="fa fa-lg fa-phone icon-p-a"></i>
            </span>
          </div> */}
          {/* <div className="toolbar-box">
             <i className="feather icon-compass ml-2 mb-1"></i> 
* <span>شرکت پترونام سهند، تولید انواع روغن موتور و روانکارهای صنعتی</span> 

          </div>
          <div className="icon-holder-box mt-1">

          </div>
          <div className="icon-toolbar-box mt-1">

            <a href="https://instagram.com/petronam.co" target="_blank">
              <span className="icon-holder icon1">
                <i className="fab fa-instagram"></i>
              </span>
            </a>
            <a href="https://web.whatsapp.com/send?phone=+989104006004&text=سلام وقت بخیر" target="_blank">
              <span className="icon-holder icon2">
                <i className="fab fa-whatsapp"></i>
              </span>
            </a>
            <a href="mailto:?subject=ارتباط با مدیریت" target="_blank">
              <span className="icon-holder icon3">
                <i className="far fa-envelope"></i>
              </span>
            </a>
            <a href="tel:+989104006004">
              <span className="icon-holder icon4">
                <i className="feather icon-phone-call"></i>
              </span>
            </a>
          </div> */}


          <div className="user-login-gbs">
            {/* <NavShopCart /> */}


            {/* 
            {
              !(userLogin && userLogin.userId) &&
              <NavUserLogin>
                <UserPanel />
              </NavUserLogin>
            }
            {userLogin && userLogin.userId && 
            <ExitUser />
            }  */}
          </div>
        </header>
      </React.Fragment>
    </header>
  );
};
export default React.memo(NavTop);
