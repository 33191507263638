import React, { createContext, useEffect, useReducer } from "react";
import jwtDecode from "jwt-decode";

import { ACCOUNT_INITIALISE, LOGIN, LOGOUT } from "../store/actions";
import axios from "../services/axios";
import accountReducer from "../store/accountReducer";
import Loader from "../components/Loader/Loader";
import AuthService, { tokenName } from "../model/AuthService";
import { IJWTUserData } from "../model/model";

const initialState = {
  isLoggedIn: false,
  isInitialised: false,
  user: null,
};

const verifyToken = (serviceToken: string) => {
  if (!serviceToken) {
    return false;
  }

  const decoded = jwtDecode(serviceToken);
  return decoded.exp > Date.now() / 1000;
};

const JWTContext = createContext({
  ...initialState,
});

export const JWTProvider = ({ children }: { children: any }) => {
  const [state, dispatch] = useReducer(accountReducer, initialState);

  useEffect(() => {
    const init = async () => {
      try {
        const serviceToken = window.localStorage.getItem(tokenName);
        if (serviceToken && verifyToken(serviceToken)) {
          const user = new AuthService().getProfile();
          if (user) {
            dispatch({
              type: ACCOUNT_INITIALISE,
              payload: {
                isLoggedIn: true,
                user,
              },
            });
          } else {
            dispatch({
              type: ACCOUNT_INITIALISE,
              payload: {
                isLoggedIn: false,
                user: null,
              },
            });
          }
        } else {
          dispatch({
            type: ACCOUNT_INITIALISE,
            payload: {
              isLoggedIn: false,
              user: null,
            },
          });
        }
      } catch (err) {
        console.error(err);
        dispatch({
          type: ACCOUNT_INITIALISE,
          payload: {
            isLoggedIn: false,
            user: null,
          },
        });
      }
    };

    init();
  }, []);

  if (!state.isInitialised) {
    return <Loader />;
  }

  return (
    <JWTContext.Provider value={{ ...state }}>{children}</JWTContext.Provider>
  );
};

export default JWTContext;
