import React, { useState, useContext, useRef, useEffect } from "react";
import "./DynamicLogin.css";
import { Link, useHistory } from "react-router-dom";
import logo from "../../../../assets/images/morvarid.png";
import { useTranslation } from "@hooks/useTranslation";
import { Card, Row, Col, Button, Form as formstyle } from "react-bootstrap";
//@ts-ignore
import PNotify from "pnotify/dist/es/PNotify";
import Api from "@model/Api";
import { ICustomer } from "@model/model";
import * as Yup from "yup";
import { Formik, Field, Form, ErrorMessage } from "formik";
import { ConfigContext } from "@contexts/ConfigContext";
import InputMask from "react-input-mask";
import { useTimer } from "react-timer-hook";
import AuthService from "@model/AuthService";

const DynamicLogin = (p: any) => {
  const { setShowModal, setShowModalDynamic, setShowModalRegister } = p;
  const configContext = useContext(ConfigContext);
  //@ts-ignore
  const { dispatch } = configContext;
  const history = useHistory();
  const [customerFullName, setCustomerFullName] = useState<string>();

  const [mobileCode, setMobileCode] = useState<string>();
  const [showMobileCode, setShowMobileCode] = useState(false);
  const [mobile, setMobile] = useState<string>();
  const [loginError, setLoginError] = useState("");

  const inputEl = useRef<any>();

  // useEffect(() => {
  //   // inputEl.current.focus();
  //   inputEl.current.getInputDOMNode().focus();
  // }, []);


  const time = new Date();
  time.setSeconds(time.getSeconds() + 120);
  const { seconds, minutes, isRunning, restart } = useTimer({
    expiryTimestamp: time,
  });

  async function onMobileFormSubmit(e: any) {
    e.preventDefault();
    new Api()
      .get(`/api/website/sendsmstoVerifyLogin/${mobile}`)
      .then(async (response) => {
        const data = await response.json();
        if (response.status === 200) {
          setCustomerFullName(data.fullName)
          setShowMobileCode(true);
          const time = new Date();
          time.setSeconds(time.getSeconds() + 120);
          restart(time);
        } else {
          PNotify.error({
            title: "عملیات ناموفق",
            text: data.errors[0].msg,
          });
        }
      });
    setLoginError("");
  }

  async function onMobileCodeFormSubmit(e: any) {
    e.preventDefault();
    const profileInfo: any = await new AuthService().loginDynamicCode({
      mobileCode: mobileCode,
      mobile: mobile,
    });
    if (profileInfo) {
      setShowModalDynamic(false)
      setShowModal(false);
      history.replace("/");
      dispatch({
        type: "LOGIN",
        openLoginForm: false,
      });
      dispatch({
        type: "LOGIN",
        openLoginForm: false,
      });
      PNotify.success({
        title: "عملیات موفق",
        text: "شما با موفقیت وارد سیستم شدید.",
      });
    }
    else {
      PNotify.error({
        title: "عملیات ناموفق",
        text: "لطفا دوباره تلاش کنید.",
      });
    }
    setLoginError("");
  }

  return (
    <section className="auth-wrapper">
      <Row className="align-items-center text-center">
        <Col md={12} className="p-5">
          <Row className="align-items-center text-center">
            <Col md={12}>
              <img
                id="main-logo"
                src={logo}
                style={{ height: "100px" }}
                alt="لوگو رستوران مروارید"
                className="logo"
              />
            </Col>
          </Row>
          <Row className="align-items-center text-center">
            <Col md={12} className="p-3">
              <p className="reg-title">
                ورود اطلاعات کاربری
              </p>
            </Col>
          </Row>
          <Row>
            {!showMobileCode && (
              <Card.Body>
                {" "}
                <h4 className="mb-3 f-w-200">شماره موبایل را وارد کنید</h4>
                <form onSubmit={onMobileFormSubmit}>
                  <InputMask
                    style={{ direction: "ltr" }}
                    autoFocus
                    // ref={inputEl}
                    className="form-control text-center mb-2"
                    mask=" 0\999  999  99  99"
                    name="mobile"
                    alwaysShowMask
                    value={mobile}
                    onChange={(e: any) => {
                      const mo = (e.target.value as string).replace(
                        /\D/g,
                        ""
                      );
                      setMobile(mo);
                    }}
                  />
                  {loginError && (
                    <div
                      style={{
                        backgroundColor: "#ff6161",
                        padding: 5,
                        borderRadius: 7,
                        margin: 7,
                        color: "#fff",
                        boxShadow: "1px 3px 10px -1px #888",
                      }}
                    >
                      {loginError}
                    </div>
                  )}
                  <button
                    disabled={!(mobile && mobile.length == 11)}
                    type="submit"
                    className={
                      "btn btn-success btn-block mb-3 " +
                      (!(mobile && mobile.length == 11) &&
                        "btn-outline-success")
                    }
                  >
                    دریافت کد
                  </button>
                </form>
                <div className="register-signin">
                  <p className="mb-1 ">
                    حساب کاربری ندارید؟
                    <a href="#" className="register-link  d-inline"
                      onClick={() => {
                        // dispatch({
                        //   type: "LOGIN",
                        //   openLoginForm: false,
                        // });
                        // setShowModal(false);
                        setShowModalRegister(true);
                        setShowModalDynamic(false);
                      }}
                    >
                      ثبت نام
                    </a>
                  </p>
                </div>
              </Card.Body>
            )}
            {showMobileCode && (
              <Card.Body>
                {" "}
                <h5 className="mb-3 f-w-200" style={{ lineHeight: "30px" }}>
                  آقا/خانم
                  <span className="text-surati  f-w-600 f-20">
                    {" "}{customerFullName}{" "}
                  </span>
                  عزیز کد ارسال شده به موبایل
                  <span className="text-surati  f-w-600 f-20">
                    {" "}{mobile}{" "}
                  </span>
                  را وارد کنید
                </h5>
                <form onSubmit={onMobileCodeFormSubmit}>
                  <InputMask
                    style={{ direction: "ltr" }}
                    // autoFocus
                    className="form-control text-center mb-2"
                    mask="9999"
                    name="code"
                    alwaysShowMask
                    value={mobileCode}
                    onChange={(e: any) => {
                      const code = (e.target.value as string).replace(
                        /\D/g,
                        ""
                      );
                      setMobileCode(code);
                    }}
                  />
                  {loginError && (
                    <div
                      style={{
                        backgroundColor: "#ff6161",
                        padding: 5,
                        borderRadius: 7,
                        margin: 7,
                        color: "#fff",
                        boxShadow: "1px 3px 10px -1px #888",
                      }}
                    >
                      {loginError}
                    </div>
                  )}

                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <p>
                      {" "}
                      <span className="f-14"> زمان باقیمانده: </span>
                      <span className="f-14 text-info">
                        {minutes}:{seconds}
                      </span>
                      {!isRunning && (
                        <Button
                          variant="link"
                          className="mr-2 p-0 f-12"
                          style={{ textDecoration: "none" }}
                          onClick={onMobileFormSubmit}
                        >
                          <span className="text-info">ارسال دوباره</span>
                          <i className="fas fa-undo text-info mr-1" />
                        </Button>
                      )}
                    </p>
                  </div>

                  <button
                    disabled={!(mobileCode && mobileCode.length == 4)}
                    type="submit"
                    className={
                      "btn btn-success btn-block mb-3 " +
                      (!(mobileCode && mobileCode.length == 4) &&
                        "btn-outline-success")
                    }
                  >
                    تایید کد
                  </button>
                </form>
              </Card.Body>
            )}
          </Row>

        </Col>
      </Row>
    </section>
  );
};
export default React.memo(DynamicLogin);
