import React, { useContext, useEffect, useReducer, useState } from "react";
import { Card, Row, Col, Button, Alert, Modal } from "react-bootstrap";
import { NavLink, Link, useHistory, useParams } from "react-router-dom";
import logo from "../../../assets/images/morvarid.png";
import { useTranslation } from "../../../hooks/useTranslation";
import AuthService from "@model/AuthService";
import InputMask from "react-input-mask";
import NewUser from "./NewUser/NewUser";
import "./SignIn.scss";

import { ConfigContext } from "../../../contexts/ConfigContext";

const SignIn = (p: any) => {
  const { setShowModal, setShowModalRegister, setShowModalDynamic } = p;
  const history = useHistory();

  const { signScreen } = useTranslation();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [loginError, setLoginError] = useState("");
  const [loading, setLoading] = useState(false);
  // const [showModalRegister, setShowModalRegister] = useState(true);

  const [rememberMe, setRememberMe] = useState(false);
  const [enableTFA, setEnableTFA] = useState(false);
  const [TFACode, setTFACode] = useState<number>();
  const configContext = useContext(ConfigContext);

  //@ts-ignore
  const { dispatch } = configContext;

  async function login() {
    setLoading(true);
    setLoginError("");
    const profileInfo: any = await new AuthService().login({
      username: username,
      password: password,
      rememberMe: rememberMe,
    });

    if (profileInfo) {
      if (profileInfo.tfa) {
        setEnableTFA(profileInfo.tfa);
      } else {
        // window.location.href = "/";
        history.replace("/");
        dispatch({
          type: "LOGIN",
          openLoginForm: false,
        });
      }
    } else {
      setLoginError(signScreen.loginErrorLabel);
      setLoading(false);
    }
  }

  async function loginTFACode() {
    setLoginError("");
    const res = await new AuthService().loginTwoFa({
      TFACode: TFACode,
      username: username,
      password: password,
      rememberMe: rememberMe,
    });
    if (res) {
      window.location.href = "/";
    } else {
      setLoginError("خطا در اتصال");
    }
  }

  return (
    <React.Fragment>
      <div className="auth-wrapper">
        {/* <div className="auth-content text-center"> */}
        <Row className="align-items-center text-center">
          <Col md={12}>
            <Row>
              <Col sm={12} className="pt-3">
                <p>  به سفارش گیر آنلاین رستوران مروارید آبرسان خوش آمدید</p>

                <img src={logo} style={{ height: "100px" }} />
                <p className="pt-2">رستوران مروارید</p>
              </Col>
            </Row>
            <Row className="align-items-center text-center">
              <Col sm={12}>
                {!enableTFA && (
                  <Card.Body className="card-body">
                    <div className="input-group mb-3">
                      <div className="input-group-append"></div>
                      <input
                        type="username"
                        onChange={(e) => setUsername(e.target.value)}
                        className="form-control"
                        value={username}
                        placeholder={signScreen.Emailaddress}
                        style={{ borderRadius: "5px" }}
                      />
                    </div>
                    <div className="input-group mb-3">
                      <div className="input-group-append"></div>
                      <input
                        type="password"
                        onChange={(e) => setPassword(e.target.value)}
                        value={password}
                        className="form-control"
                        placeholder={signScreen.Password}
                        style={{ borderRadius: "5px" }}
                      />
                    </div>

                    <div
                      className="custom-control custom-checkbox text-left mb-2 mt-2"
                    >
                      <input
                        style={{ width: "100%", zIndex: 200 }}
                        type="checkbox"
                        className="custom-control-input"
                        id="rememberMeId"
                        checked={rememberMe}
                        onChange={(e) => {
                          setRememberMe(e.target.checked);
                        }}
                      />
                      <label
                        className="custom-control-label f-12"
                        htmlFor="rememberMeId"
                      >
                        {signScreen.RememberMe}
                      </label>
                    </div>

                    {loginError && (
                      <div
                        style={{
                          backgroundColor: "#ff6161",
                          padding: 5,
                          borderRadius: 7,
                          margin: 7,
                          color: "#fff",
                          boxShadow: "1px 3px 10px -1px #888",
                        }}
                      >
                        {loginError}
                      </div>
                    )}

                    <a href="#" className="default-btn text-white login-btn ml-1"
                      onClick={login}
                    >
                      {loading == true ? (
                        <span
                          className="spinner-border spinner-border-sm mr-1"
                          role="status"
                        />
                      ) : (
                        ""
                      )}
                      {signScreen.SigninTitle}
                    </a>

                    <a href="#" className="default-btn text-white login-btn mr-1"
                      onClick={() => {
                        dispatch({
                          type: "LOGIN",
                          openLoginForm: false,
                        });
                        setShowModal(false);
                        setShowModalDynamic(true);
                      }}
                    >
                      {loading == true ? (
                        <span
                          className="spinner-border spinner-border-sm mr-1"
                          role="status"
                        />
                      ) : (
                        ""
                      )}
                      ورود با رمز یکبار مصرف
                    </a>

                    <div className="register-signin">
                      <p className="mb-1 ">
                        حساب کاربری ندارید؟
                        <a href="#" className="register-link  d-inline"
                          onClick={() => {
                            dispatch({
                              type: "LOGIN",
                              openLoginForm: false,
                            });
                            setShowModal(false);
                            setShowModalRegister(true);
                          }}
                        >
                          ثبت نام
                        </a>
                      </p>
                    </div>
                  </Card.Body>
                )}
                {enableTFA && (
                  <Card.Body>
                    {" "}
                    <img src={logo} alt="" className="img-fluid mb-2" />
                    <h4 className="mb-3 f-w-200"></h4>
                    <InputMask
                      className="form-control text-center mb-2"
                      mask="999999"
                      name="code"
                      alwaysShowMask
                      value={TFACode}
                      onChange={(e: any) => {
                        const code = e.target.value as number;
                        setTFACode(code);
                      }}
                    />
                    {loginError && (
                      <div
                        style={{
                          backgroundColor: "#ff6161",
                          padding: 5,
                          borderRadius: 7,
                          margin: 7,
                          color: "#fff",
                          boxShadow: "1px 3px 10px -1px #888",
                        }}
                      >
                        {loginError}
                      </div>
                    )}
                    <button
                      style={{
                        color: "#030d2a",
                        backgroundColor: "#030d2a",
                      }}
                      className={
                        "btn btn-block mb-3 "
                        // +
                        // (captchaOk == true ? "" : "disabled")
                      }
                      onClick={async () => {
                        await loginTFACode();
                      }}
                    >
                      {signScreen.SigninTitle}
                    </button>
                  </Card.Body>
                )}
              </Col>
            </Row>
          </Col>
        </Row>
        {/* </div> */}
      </div >
    </React.Fragment >
  );
};

export default SignIn;
