import {createContext,useEffect,useState} from 'react'

export const settings=createContext();

export const SettingsProvider=(prop)=>{
const [lang,setLang]=useState('fa');
const[login,setLogin]=useState(false);

const [cartcount,Setcartcount]=useState(JSON.parse(localStorage.getItem('cartcount')) || 0);
const [cart,Setcart]=useState(JSON.parse(localStorage.getItem('cart')) || []);

return(
    <settings.Provider value={{lang,setLang,cartcount,Setcartcount,cart,Setcart,login,setLogin}}>
        {prop.children}
    </settings.Provider>
);

}