import React from "react";
import "./Footer.css";
// import "../../../components/morvarid/Menu-Card.scss";
// import logo from "../../../assets/images/main-logo.png";
import logo from "../../../assets/images/morvarid.png";
import kasbokarlogo from "../../../assets/images/kasbokar.png";
import unitabrizlogo from "../../../assets/images/unitabriz.png";
import { useTranslation } from "@hooks/useTranslation";
function Footer() {
  const { footer, navTop } = useTranslation();

  return (
    <div className="footer">
      <section>
        <div className="column1">
          <img src={logo} alt="لوگو رستوران مروارید آبرسان" className="footer-logo" />
          <div className="content">
            <div>
              <i className="fas fa-utensils text-surati f-24" /> <br />
              رستوران مروارید تبریز<br />
              تهیه و ارسال غذاهایی مثل آبگوشت، تاواکبابی و کباب کوبیده را برعهده دارد.
            </div>
            <div className="icon-footer-box mt-1">
              <a href="https://instagram.com/restaurant.morvarid" target="_blank">
                <span className="icon-holder icon1">
                  <i className="fab fa-instagram " style={{ fontSize: "1.3rem" }}></i>
                </span>
              </a>
              <a href="https://web.whatsapp.com/send?phone=+989104006004&text=سلام وقت بخیر" target="_blank">
                <span className="icon-holder icon2">
                  <i className="fab fa-whatsapp" style={{ fontSize: "1.3rem" }}></i>
                </span>
              </a>
              <a href="tel:+989104006004">
                <span className="icon-holder icon4">
                  <i className="feather icon-phone-call" style={{ fontSize: "1.3rem" }}></i>
                </span>
              </a>
            </div>
            <div className="social">
              {/* <ul>
                
                <li>
                  <a href="http://wa.me/989141181424" target="_blank">
                    <i className="fab fa-lg fa-whatsapp"></i> 09141181424
                  </a>
                </li> 
              </ul>*/}
            </div>
          </div>
        </div>
        <div className="column2 section-title-footer">
          <h3>ساعات کاری</h3>
          <div className="bar mb-3"></div>
          <ul className="footer-contact-info">
            <li className="footer-li-mobile">
              <a>همه روزه بجز جمعه‌ها</a>
              <i className="far fa-calendar-alt"></i>
            </li>
            <li className="footer-li-mobile">
              <a>ناهار - شام</a>
              <i className="fas fa-clock"></i>

            </li>
          </ul>
        </div>

        <div className="column3 section-title-footer">
          <h3>اطلاعات تماس</h3>
          <div className="bar mb-3"></div>
          <ul className="footer-contact-info">
            <li className="footer-li-mobile">
              <span>تلفن</span>
              <a href="tel:04136565776">04136565776</a>
              <i className="fa fa-phone"></i>
            </li>
            <li className="footer-li-mobile">
              <span>موبایل</span>
              <a href="tel:09141181424"> 09141181424</a>
              <i className="material-icons  f-28">smartphone</i>
            </li>
            <li className="footer-li-mobile">
              <span>آدرس رستوران</span>
              <a href="https://www.google.com/maps/place/%D8%B1%D8%B3%D8%AA%D9%88%D8%B1%D8%A7%D9%86+%D9%85%D8%B1%D9%88%D8%A7%D8%B1%DB%8C%D8%AF+%D8%A2%D8%A8%D8%B1%D8%B3%D8%A7%D9%86%E2%80%AD/@38.0714773,46.3227642,17z/data=!4m5!3m4!1s0x401a1bf9b47cc6eb:0x5f9e3f49b3d1eb7d!8m2!3d38.0714662!4d46.3249795" target="_blank">
                آبرسان، نرسیده به بیمارستان شمس،
                نبش فلکه هفت تیر، رستوران مرواریدآبرسان
              </a>
              <i className="material-icons">room</i>
            </li>
          </ul>
        </div>
      </section >

      <section className="copyright">
        <div>تمام حقوق سایت محفوظ می باشد.</div>
        <div>
          طراحی و توسعه{" "}
          <a href="https://guneshco.com/" target="_blank">
            {" "}
            شرکت گونش بیلگی سایار سهند{" "}
          </a>
        </div>
      </section>
    </div >
  );
}
export default Footer;
