import JWTContext from "@contexts/JWTContext";
import React, { Suspense, Fragment, lazy, useContext } from "react";
import { Switch, Redirect, Route } from "react-router-dom";

import Loader from "./components/Loader/Loader";
import AdminLayout from "./layouts/AdminLayout";
import { BASE_URL } from "./config/constant";

export const renderRoutes = (routes = []) => (
  <Suspense fallback={<Loader />}>
    <Switch>
      {routes.map((route, i) => {
        const Guard = route.guard || Fragment;
        const Layout = route.layout || Fragment;
        const Component = route.component;
        return (
          <Route
            key={i}
            props={route.props}
            path={route.path}
            exact={route.exact}
            render={(props) => (
              <Guard>
                <Layout>
                  {route.routes ? (
                    renderRoutes(route.routes)
                  ) : (
                    <Component {...props} />
                  )}
                </Layout>
              </Guard>
            )}
          />
        );
      })}
    </Switch>
  </Suspense>
);

const routes = [
  {
    path: "*",
    layout: AdminLayout,
    routes: [
      {
        exact: true,
        path: "/",
        component: lazy(() => import("./views/morvarid/Home")),
      },
      {
        exact: true,
        path: "/products",
        component: lazy(() => import("./views/ecommerce/Products/Products")),
      },

      {
        exact: true,
        path: "/weblog",
        component: lazy(() => import("./views/ecommerce/Weblog/Weblog")),
      },
      {
        exact: true,
        path: "/aboutus",
        component: lazy(() => import("./views/morvarid/AboutUs/AboutUs")),
      },

      {
        exact: true,
        path: "/contactus",
        component: lazy(() =>
          import("./views/morvarid/Contactus/Contactus.jsx")
        ),
      },

      {
        exact: true,
        path: "/search",
        component: lazy(() =>
          import("./views/ecommerce/Searchpage/Searchpage")
        ),
      },

      {
        exact: true,
        path: "/blogdetile",
        component: lazy(() =>
          import("./views/ecommerce/Blogdetile/Blogdetile")
        ),
      },
      {
        exact: true,
        path: "/foodorder",
        component: lazy(() => import("./views/morvarid/MenuCard")),
      },
      {
        exact: true,
        path: "/blog",
        component: lazy(() =>
          import("./views/morvarid/blog/Blog")
        ),
      },
      {
        exact: true,
        path: "/blog/category/:blogCategoryName",
        component: lazy(() =>
          import("./views/morvarid/blog/Blog")
        ),
      },
      {
        exact: true,
        path: "/blog/keyword/:blogKeywordName",
        component: lazy(() =>
          import("./views/morvarid/blog/Blog")
        ),
      },
      {
        exact: true,
        path: "/blog/:blogId",
        component: lazy(() =>
          import("./views/morvarid/blog/BlogDetail")
        ),
      },
      {
        exact: true,
        path: "/food",
        component: lazy(() =>
          import("./views/morvarid/foods/FoodSliders")
        ),
      },

      {
        exact: true,
        path: "/food/:goodId/:goodName",
        component: lazy(() =>
          import("./views/morvarid/foods/FoodDetail")
        ),
      },

      {
        exact: true,
        path: "/404",
        component: lazy(() => import("./views/maintenance/OfflineUI")),
      },
      {
        path: "*",
        exact: true,
        component: () => <Redirect to="/404" />,
      },
    ],
  },
];

export default routes;
