import React, { useEffect, useState } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import routes, { renderRoutes } from "./routes";
import { BASENAME } from "./config/constant";
import { TranslationContainer } from "./localization/LocalizationProvider";
import { JWTProvider } from "./contexts/JWTContext";
import { SettingsProvider } from "@contexts/settings";

function App() {
  // window.onbeforeunload = function () {
  //   localStorage.clear();
  // };
  return (
    <SettingsProvider>
      <TranslationContainer.Provider>
        <React.Fragment>
          <JWTProvider>
            <Router basename={BASENAME}>{renderRoutes(routes)}</Router>
          </JWTProvider>
        </React.Fragment>
      </TranslationContainer.Provider>
    </SettingsProvider>
  );
}

export default App;
