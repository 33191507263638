import { checkPropTypes } from "prop-types";
import React, { useState, useRef, useEffect, useContext } from "react";
import "./NavSearchBox.css";
import { settings } from "@contexts/settings";
import { useHistory } from "react-router-dom";
import { useTranslation } from "../../../../hooks/useTranslation";
import image1 from "../../../../assets/images/product/pa1.png";
import image2 from "../../../../assets/images/product/pa2.png";
import image3 from "../../../../assets/images/product/pa3.png";
import pic1 from "../../../../assets/images/confirmation/confirmation1.jpg";
import pic2 from "../../../../assets/images/confirmation/confirmation2.jpg";
import pic3 from "../../../../assets/images/confirmation/confirmation3.jpg";
import pic4 from "../../../../assets/images/confirmation/confirmation4.jpg";


const itemProduct = [
  {
    id: 1,
    category: "لوازم جانبی موبایل",
    title: "هدفون مدل جذاب و پر طرفدار و پرفروش A123",
    pic: [image1, image2, image3],
    oldprice: "85000",
    newprice: "43500",
    off: 50,
    favorite: true,
    url: "/products",
  },
  {
    id: 2,
    category: "ساعت",
    title: "پوشاک مدل جذاب و پر طرفدار و پرفروش A123",
    pic: [image2, image3, image1],
    oldprice: "44000",
    newprice: "43500",
    off: 5,
    favorite: true,
    url: "/products",
  },
  {
    id: 3,
    category: "پوشاک",
    title: "پوشاک مدل جذاب و پر طرفدار و پرفروش A123",
    pic: [image3, image2, image1],
    oldprice: "150000",
    newprice: "150000",
    off: "",
    favorite: false,
    url: "/products",
  },
  {
    id: 3,
    category: "پوشاک",
    title: "پوشاک مدل جذاب و پر طرفدار و پرفروش A123",
    pic: [image3, image2, image1],
    oldprice: "150000",
    newprice: "150000",
    off: "",
    favorite: false,
    url: "/products",
  },
  {
    id: 3,
    category: "پوشاک",
    title: "پوشاک مدل جذاب و پر طرفدار و پرفروش A123",
    pic: [image3, image2, image1],
    oldprice: "150000",
    newprice: "150000",
    off: "",
    favorite: false,
    url: "/products",
  },
  {
    id: 3,
    category: "کیف",
    title: "پوشاک مدل جذاب و پر طرفدار و پرفروش A123",
    pic: [image3, image2, image1],
    oldprice: "150000",
    newprice: "150000",
    off: "",
    favorite: false,
    url: "/products",
  },
  {
    id: 3,
    category: "ساعت",
    title: "پوشاک مدل جذاب و پر طرفدار و پرفروش A123",
    pic: [image3, image2, image1],
    oldprice: "150000",
    newprice: "150000",
    off: "",
    favorite: false,
    url: "/products",
  },
];

const itemConfirms = [
  {
    id: 1,
    title: "پروانه کاربرد علامت استاندارد اجباری - روغن دنده ساده",
    pic: [pic1, pic2, pic3, pic4],
    alt: "1",
  },
  {
    id: 2,
    title:
      "پروانه کاربرد علامت استاندارد اجباری - روان کننده ها ،روغن موتور دیزلی  ",
    pic: [pic2, pic3, pic4, pic1],
    alt: "2",
  },
  {
    id: 3,
    title:
      "پروانه کاربرد علامت استاندارد اجباری - روان کننده ها ،روغن موتور بنزینی  ",
    pic: [pic3, pic4, pic1, pic2],
    alt: "3",
  },
  {
    id: 4,
    title: "پروانه کاربرد علامت استاندارد اجباری - روغن دنده هیپوئید",
    pic: [pic4, pic3, pic1, pic2],
    alt: "3",
  },
];

const NavSearchBox = (props) => {
  const [searchBarValue, setSreachBarValue] = useState("");
  const [itemData, setItemData] = useState();
  // const { lang, setLang } = useContext(settings);
  let history = useHistory();

  // {pathname:'/aboutus',search:'?sort=id',state:{fromDashbord:true}}

  const inputEl = useRef(null);
  useEffect(() => {
    window.scrollTo(0, 0);
    setItemData();
    inputEl.current.focus();
    console.log(history);
  }, []);

  const { navSearchBox } = useTranslation();
  const searchFilterFnc = (e) => {
    const itemDataHoldrProuduct = itemProduct.filter((item) => {
      const textData = e.target.value.toUpperCase();
      if (
        item.title.toUpperCase().includes(textData) ||
        item.category.toUpperCase().includes(textData)
      )
        return item;
    });

    const itemDataHoldrConfirm = itemConfirms.filter((item) => {
      const textData = e.target.value.toUpperCase();
      if (item.title.toUpperCase().includes(textData)) return item;
    });

    let itemDataHoldr = [...itemDataHoldrProuduct, ...itemDataHoldrConfirm];
    console.log("item: ", itemDataHoldr);
    setItemData(itemDataHoldr);
    setSreachBarValue(e.target.value);
  };
  console.log("input: ", searchBarValue);

  function handleenterclick(e) {
    if (e.key === "Enter") {
      history.push({
        pathname: "/search",
        state: { result: itemData },
      });
    }
    console.log("history", history);
  }

  return (
    <React.Fragment>
      <div className="SearchBoxG">
        <button href="/" className="SearchG">
          <i
            className="fa fa-lg fa-search fa-flip-horizontal iconG"
            aria-hidden="true"
          ></i>
        </button>
        <input
          type="text"
          placeholder={navSearchBox.NavSearchBoxTitle}
          value={searchBarValue}
          onChange={searchFilterFnc}
          onKeyPress={handleenterclick}
          ref={inputEl}
        />
      </div>
    </React.Fragment>
  );
};
export default React.memo(NavSearchBox);
